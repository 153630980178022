import React from 'react';
import styles from './Step.module.css';
import {PortableText} from '@portabletext/react'
import { useState } from 'react';
import { portableTextComponents } from './portableTextComponents';

const Step = ({body, title}) => {

    const [complete, setComplete] = useState(false);

    return (
        <div>
            <h4 className={styles.articleHead}>
                <input type="checkbox" checked={complete} onChange={() => setComplete(!complete)} className={styles.completeCheck}/>
                {title} 
                {complete && <span className={`${styles.check}`}>(Complete)</span>}
            </h4>
            { !complete &&
                <PortableText value={body} components={portableTextComponents}/>
            }
        </div>
    )
}

export default Step;