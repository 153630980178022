import styles from './App.module.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
import Article from './pages/Article';
import Category from './pages/Category';
import Search from './pages/Search';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Navbar from './components/Navbar'
import Footer from './components/Footer'

function App() {
  return (
    <div className={styles.app}>
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/search" element={<Search/>} />
            <Route path="/category" element={<Category/>} />
            <Route path="/article/:articleId" element={<Article/>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
            <Route path="/contact" element={<Contact/>} />
          </Routes>
          <Footer />
        </Router>
    </div>
  );
}
export default App;
