import React, {useState, useEffect} from 'react';
import styles from './Sidebar.module.css';
import { getOutline } from '../queries';
import { Link } from 'react-router-dom';

const Sidebar = () => {

    const [featured, setFeatured] = useState([]);
  
    useEffect(() => {
        getOutline().then((res, err) => {
            setFeatured(res.featured);
        })
    }, [])

    return (
        <div className={styles.sidebar}>
            <h4 className={styles.header}>Featured Articles</h4>
            <ul className={styles.featuredList}>
                { featured && featured.map(f => (
                    <Link to={`/article/${f._id}`} key={f._id}><li className={styles.featuredItem}>{f.title}</li></Link>
                ))
                }
            </ul>
        </div>
    )
}

export default Sidebar