import React, { useState } from 'react';
import styles from './Navbar.module.css';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {

    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");

    const onSearchBtn = () => {
        navigate(`/search?term=${searchText}`)
    }

    return (
        <div className={styles.nav}>
        <Link to="/"><h2 className={styles.navTitle}>Programming Pupil</h2></Link>
        <h3 className={styles.navSubTitle}>Learn About Computer Programming</h3>
            <div className={styles.linkBar}>
                <div className={styles.linkContainer}>
                    <ul className={styles.linkList}>
                        <Link to="/category?categoryName=JavaScript"><li className={styles.linkItem}>JavaScript</li></Link>
                        <Link to="/category?categoryName=Cloud Computing"><li className={styles.linkItem}>Cloud Computing</li></Link>
                    </ul>
                    <div className={styles.searchGroup}>
                        <input type="text" placeholder="Search" className={styles.searchInput} value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
                        <i className="fa-solid fa-magnifying-glass" onClick={() => onSearchBtn()}></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar