import styles from './portableTextComponents.module.css';
import SanityImage from '../components/SanityImage';
import Step from './Step';

export const portableTextComponents = {
    types: {
        image: ({ value }) => {
            return (
                <SanityImage {...value} />
            );
        },
        step: ({value}) => <Step {...value}/>
    },
    list: {
        bullet: ({children}) => <ul className={styles.articleList}>{children}</ul>
    },
    listItem: {
        bullet: ({children}) => <li className={styles.articleListItem}>{children}</li>
    },
    block: {
        h4: ({children}) => <h4 className={styles.articleHead}>{children}</h4>,
        normal: ({children}) => <p className={styles.articleParagraph}>{children}</p>,
        code: ({children}) => <p className={styles.articleCode}>{children}</p>
    },
};