import React, { useState } from 'react';
import imageUrlBuilder from '@sanity/image-url'
import sanityClient from '@sanity/client';
import styles from './SanityImage.module.css';
import ImageModal from './ImageModal';

export const builder = imageUrlBuilder(sanityClient({
    projectId: 't5q8qcgj',
    dataset: 'production',
    apiVersion: '2021-03-25', // use current UTC date - see "specifying API version"!
    token: '', // or leave blank for unauthenticated usage
    useCdn: true, // `false` if you want to ensure fresh data
}))

const SanityImage = ({asset}) => {

    const imageUrl = builder.image(asset);
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <>
            <div className={styles.imgContainer}>
                <img src={imageUrl} className={styles.articleImg} onClick={() => setModalVisible(true)}></img>
            </div>
            <ImageModal visible={modalVisible} imgUrl={imageUrl} onClose={() => setModalVisible(false)}/>
        </>
    )
}

export default SanityImage;