import axios from 'axios';
import {API_ROOT} from './const.js';
import { formatArticles } from './util.js';

export const getOutline = async () => {

  try {
    let query = `*[_type == "outline"]{featured[]->}`;
    const res = await axios.get(`${API_ROOT}/data/query/production?query=${encodeURIComponent(query)}`);

    return res.data.result[0]

  } catch (err) {
    return "Error " + err;

  }
}

export const getAllArticles = async () => {

    try {
        let query = `*[_type == "post"]`;
        const res = await axios.get(`${API_ROOT}/data/query/production?query=${encodeURIComponent(query)}`);
    
        return res.data.result;
    
      } catch (err) {
        return "Error " + err;

      }
}

export const getArticlesOfCategory = async (categoryName) => {

    try {
      let query = `*[_type == "category" && title == "${categoryName}"]{"articles": *[_type=='post' && references(^._id)]}`;
      const res = await axios.get(`${API_ROOT}/data/query/production?query=${encodeURIComponent(query)}`);
  
      if(res.data.result && res.data.result.length > 0){
        return res.data.result[0].articles;
      }

      return [];
  
    } catch (err) {
      return "Error " + err;

    }
}

export const getArticlesWithTerm = async (term) => {

  try {
      let query = `*[_type == "post" && title match "${term}*"]`;
      const res = await axios.get(`${API_ROOT}/data/query/production?query=${encodeURIComponent(query)}`);
  
      return res.data.result;
  
    } catch (err) {
      return "Error " + err;

    }
}

export const getRecipesWithTerm = async (term) => {

  try {
      let query = `*[_type == "recipe" && title match "${term}*"]`;
      const res = await axios.get(`${API_ROOT}/data/query/production?query=${encodeURIComponent(query)}`);
  
      return res.data.result;
  
    } catch (err) {
      return "Error " + err;

    }
}

export const getArticleByID = async (articleID) => {

  try {
    let query = `*[_type == "post" && _id == "${articleID}"]{_id, title, body, mainImage, author->, publishedAt}`;
    const res = await axios.get(`${API_ROOT}/data/query/production?query=${encodeURIComponent(query)}`);

    let r = res.data.result[0];

    if(r){
      return {
        articleID: r._id,
        articleTitle: r.title,
        articleDescription: r.body[0].children[0].text,
        articleDate: r.publishedAt,
        articleImg: r.mainImage.asset._ref,
        articleBody: r.body,
        articleAuthor: r.author.name
      }
    }

    return {}

  } catch (err) {
    return "Error " + err;
  }
}