import axios from 'axios';
import { useState } from 'react';
import styles from './Contact.module.css';

const Contact = () => {

    const [name, setName] = useState("");
    const [subject, setSubject] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [delivered, setDelivered] = useState(false);

    const onSubmitBtn = async () => {

        let body = {
            firstName: name,
            subject,
            email,
            message
        }

        try {
            await axios.post(`https://29oddee2eh.execute-api.us-east-1.amazonaws.com/dev/contact`, body);

        } catch (error) {
            console.log("Error sending message!");
        }

        setName("");
        setSubject("");
        setEmail("");
        setMessage("");
        setDelivered(true);
    }

    return (
        <section className={styles.contactPage}>
            <h4 className={styles.pageTitle}>Contact Us</h4>
            <div className={styles.inputForm}>
                <div className={styles.inputGroup}>
                    <label htmlFor="name" className={styles.inputLabel}>Name</label>
                    <input type="text" name="name" id="name" className={styles.input} value={name} onChange={(e) => setName(e.target.value)}/>
                </div>
                <div className={styles.inputGroup}>
                    <label htmlFor="email" className={styles.inputLabel}>Email</label>
                    <input type="text" name="email" id="email" className={styles.input} value={email} onChange={(e) => setEmail(e.target.value)}/>
                </div>
                <div className={styles.inputGroup}>
                    <label htmlFor="subject" className={styles.inputLabel}>Subject</label>
                    <input type="text" name="subject" id="subject" className={styles.input} value={subject} onChange={(e) => setSubject(e.target.value)}/>
                </div>
                <div className={styles.inputGroup}>
                    <label htmlFor="message" className={styles.inputLabel}>Message</label>
                    <textarea name="message" id="message" className={styles.inputArea} value={message} onChange={(e) => setMessage(e.target.value)}/>
                </div>
                <button className={styles.submitBtn} onClick={() => onSubmitBtn()} disabled={name.length === 0 || subject.length === 0 || email.length === 0 || message.length === 0}>Submit</button>
                {
                    delivered && <p className={styles.sentMsg}>Message Sent!</p>
                }
            </div>
        </section>
    )
}

export default Contact;