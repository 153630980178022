import imageUrlBuilder from '@sanity/image-url'
import sanityClient from '@sanity/client'

export const builder = imageUrlBuilder(sanityClient({
    projectId: 't5q8qcgj',
    dataset: 'production',
    apiVersion: '2021-03-25', // use current UTC date - see "specifying API version"!
    token: '', // or leave blank for unauthenticated usage
    useCdn: true, // `false` if you want to ensure fresh data
  }))

export function urlFor(source) {
  return builder.image(source)
}

export const formatArticles = (articles) => {

  let formatted = [];
  for(let r of articles){
    formatted.push({
      articleID: r._id,
      articleTitle: r.title,
      articleDescription: r.body[0].children[0].text,
      articleDate: r.publishedAt,
      articleImg: r.mainImage.asset._ref,
      articleBody: r.body
    })
  }

  return formatted;
}