import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getArticlesWithTerm } from '../queries';
import styles from './Search.module.css';
import Sidebar from '../components/Sidebar';
import Spinner from '../components/Spinner';
import ResultList from '../components/ResultList';

const Search = () => {

    let [searchParams, setSearchParams] = useSearchParams();
    let term = searchParams.get("term");

    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setArticles([]);
        setLoading(true);
        getArticlesWithTerm(term).then((res) => {
            setArticles(res);
            setLoading(false);
        });
    }, [term])

    return (
        <div className={styles.searchPage}>
            <div className={styles.mainContent}>
                <h1 className={styles.pageHeader}><span className={styles.catSpan}>Search Results:</span> {term}</h1>
                {
                    loading ?
                        <Spinner />
                    :
                        <ResultList articles={articles}/>
                }
            </div>
            <Sidebar />
        </div>
    )
}

export default Search;