import React from 'react';
import styles from './ArticleItem.module.css';

import { urlFor } from '../util';
import { useNavigate } from 'react-router-dom';
 
const ArticleItem = ({article}) => {

    const navigate = useNavigate();

    const onReadBtn = () => {
        navigate(`/article/${article._id}`)
    }

    return (
        <li className={styles.cardItem}>
            <img src={urlFor(article.mainImage.asset._ref)} alt="" className={styles.itemImg}/>
            <div className={styles.articleContent}>
            <div>
                <h3 className={styles.itemHeader}>{article.title}</h3>
                <p className={styles.itemDesc}>{article.body[0].children[0].text}</p>
            </div>
            <div className={styles.itemBottom}>
                <p className={styles.itemDate}>{new Date(article.publishedAt).toLocaleDateString('en-US')}</p>
                <button className={styles.readBtn} onClick={() => onReadBtn()}>Read More</button>
            </div>
            </div>
        </li>
    )
}

export default ArticleItem;