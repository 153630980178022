import React from 'react';
import styles from './ImageModal.module.css';

const ImageModal = ({visible, imgUrl, onClose}) => {

    if(!visible){return null}

    return (
        <div className={styles.imageModal}>
            <button className={styles.exitBtn} onClick={() => onClose()}><i class="fa-solid fa-x"></i></button>
            <img className={styles.modalImg} src={imgUrl}></img>
        </div>
    )
}

export default ImageModal;