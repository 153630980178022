import React from 'react';
import styles from './ResultList.module.css';
import ArticleItem from './ArticleItem';

const ResultList = ({articles}) => {

    if(!articles || articles.length === 0){
        return <li className={styles.emptyNote}>Sorry, There's Nothing Here</li>
    }

    return (
        <ul className={styles.resultList}>
        { 
            articles.map(a => (
                <ArticleItem article={a} key={a._id}/>
            ))
        }
    </ul>
  )
}

export default ResultList;