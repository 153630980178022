import React, {useState, useEffect} from 'react';
import styles from './PrivacyPolicy.module.css';
import {getArticlesWithTerm } from '../queries';
import { portableTextComponents } from '../components/portableTextComponents';
import {PortableText} from '@portabletext/react';
import Spinner from '../components/Spinner';


const PrivacyPolicy = () => {

    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getArticlesWithTerm("Privacy Policy").then((res) => {
            setArticle(res[0]);
            setLoading(false);
        });
    }, [])

    console.log(article)

    return (
        <div className={styles.privacyPage}>
            <h2 className={styles.pageHeader}>Privacy Policy</h2>
            <div className={styles.mainContent}>
                {
                    loading ?
                        <Spinner />
                    :
                        article && <PortableText value={article.body} components={portableTextComponents}/>
                }
            </div>
        </div>
    )
}

export default PrivacyPolicy