import React, {useState, useEffect} from 'react';
import styles from './Article.module.css';
import {urlFor} from '../util';
import { getArticleByID } from '../queries';
import { useNavigate, useParams } from 'react-router-dom';
import {PortableText} from '@portabletext/react'
import Sidebar from '../components/Sidebar';
import { portableTextComponents } from '../components/portableTextComponents';
import Spinner from '../components/Spinner'

const Article = () => {

    const navigate = useNavigate();
    let {articleId} = useParams();

    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getArticleByID(articleId).then((res) => {
            setLoading(false);
            setArticle(res);
        });
    }, [articleId])

    const onBackBtn = () => {
        navigate(-1);
    }

    const mainContent = () => {
        return (
            <div className={styles.mainContent}>
                <p className={styles.mobileBackBtn} onClick={() => onBackBtn()}><i className="fa-solid fa-arrow-left"></i>  Go Back</p>
                <div className={styles.articleHead}>
                <i className={`fa-solid fa-arrow-left ${styles.backBtn}`} onClick={() => onBackBtn()}></i>
                <img src={urlFor(article.articleImg)} alt="" className={styles.articleMainImage}/>
                <div className={styles.articleDetails}>
                    <h2 className={styles.articleTitle}>{article.articleTitle}</h2>
                    <p className={styles.articleAuthor}>{article.articleAuthor}</p>
                    <p className={styles.articleDate}>{new Date(article.articleDate).toLocaleDateString('en-US')}</p>
                </div>
                </div>
                <div className={styles.articleBody}>
                <PortableText value={article.articleBody} components={portableTextComponents}/>
                </div>
            </div>
        )
    }

  return (
    <div className={styles.articlePage}>
        {
            loading ?
                <div className={styles.spinnerContainer}>
                    <Spinner />
                </div>
            :
                article && mainContent()
        }
        <Sidebar />
    </div>
  )
}

export default Article