import React from 'react';
import styles from './Spinner.module.css';

const Spinner = () => {
    return (
        <div className={styles.spinnerContainer}>
            <img src="/spinner.gif" className={styles.spinner}/>
            <p className={styles.loadingText}>Loading</p>
        </div>
    )
}

export default Spinner;