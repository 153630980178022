import React from 'react';
import styles from "./Footer.module.css";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className={styles.footer}>
        <ul className={styles.linkBar}>
            <Link to="/privacy-policy"><li className={styles.linkItem}>Privacy Policy</li></Link>
            <Link to="/contact"><li className={styles.linkItem}>Contact Us</li></Link>
        </ul>
        <div className={styles.copyright}>Copyright © 2022. All Rights Reserved</div>
    </div>
    )
}

export default Footer;