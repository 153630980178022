import React, { useState, useEffect } from 'react'
import styles from './Home.module.css'
import ArticleItem from '../components/ArticleItem';
import { getOutline } from '../queries';
import Spinner from '../components/Spinner';

const Home = () => {

  const [featured, setFeatured] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getOutline().then((res, err) => {
      setFeatured(res.featured);
      setLoading(false);
    })
  }, [])

  return (
    <div className={styles.homePage}>
      <div className={styles.homeContainer}>
        <ul className={styles.featuredList}>
        { !loading?
          featured.map(f => (
            <ArticleItem article={f} key={f._id}/>
          ))
          :
          <Spinner />
        }
        </ul>
      </div>
    </div>
  )
}

export default Home