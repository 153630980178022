import React, {useState, useEffect} from 'react';
import { useSearchParams } from 'react-router-dom';

import styles from './Category.module.css';
import { getArticlesOfCategory } from '../queries';
import Sidebar from '../components/Sidebar';
import Spinner from '../components/Spinner';
import ResultList from '../components/ResultList';

const Category = () => {

    let [searchParams, setSearchParams] = useSearchParams();
    let categoryName = searchParams.get("categoryName");

    const [loading, setLoading] = useState(false);
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        setArticles([]);
        setLoading(true);
        getArticlesOfCategory(categoryName).then((res) => {
            setArticles(res);
            setLoading(false);
        });
    }, [categoryName])

    return (
        <div className={styles.searchPage}>
            <div className={styles.mainContent}>
                <h1 className={styles.pageHeader}><span className={styles.catSpan}>Category:</span> {categoryName}</h1>
                {
                    loading ? 
                        <Spinner />
                    :
                        <ResultList articles={articles} />
                }
            </div>
            <Sidebar />
        </div>
    )
}

export default Category;